import React from 'react'
import Page from '../Page'
import HeroCta from '../HeroCta'

const SignUp = () => {
  return (
    <Page.Panel color='brand'>
      <HeroCta url='/signup/' buttonText='Sign up' />
    </Page.Panel>
  )
}

export default SignUp
